/* global $ */
import "../../css/app/components/home.scss";
import "../../css/app/components/gallery.scss";
class Home
{
    constructor () 
    {
        import(/* webpackChunkName: "slick" */ '../components/slick').then(() => {
            $('.slick-carousel').slick({
                autoplay: true,
                fade: true,
                arrows: false,
                speed: 2000,
                autoplaySpeed: 2000,
            });
            $('.feature-gallery-carousal').slick({
                autoplay: true,
                arrows: true,
                slidesToShow: 1,
                slidesToScroll: 1,
                fade: true,
                cssEase: 'linear',
                speed: 1000,
                autoplaySpeed: 4000,
                pauseOnHover:false,
            }); 
            $('.video-carousel').slick({
                autoplay: false,
                arrows: true,
                slidesToShow: 4,
                slidesToScroll: 1,
                responsive: [
                    {
                        breakpoint: 1199,
                        settings: {
                            slidesToShow: 3,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 991,
                        settings: {
                            slidesToShow: 2,
                            slidesToScroll: 1,
                        }
                    },
                    {
                        breakpoint: 500,
                        settings: {
                            slidesToShow: 1,
                            slidesToScroll: 1,
                        }
                    }
                ]
            });   
        });
        console.log('Home initialised');
    }
}

new Home;